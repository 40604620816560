define([
  'modules/common/components/locale',
], (
  Locale,
) => ({

  alertShown: 0,

  reloadOnError(e) {
    const reloadVar = 'reload_on_error';
    const lastReloadTime = this.getQueryVariable(reloadVar);
    const now = (new Date()).getTime();
    if (lastReloadTime + 10000 > now) { // auto reload in last 10s
      if (this.alertShown + 60000 < now) { // alert shown more than 60s ago
        alert(
          Locale.translate('failed_to_reload_please_reboot_to_pos_error_{error}', {
            error: JSON.stringify(e),
          }),

        );
        this.alertShown = now;
      }
    } else {
      let { search } = window.location;
      if (search) {
        search = search.replaceAll(/&reload_on_error=\d+/, '');
        search += `&${reloadVar}=${now}`;
      } else {
        search = `?${reloadVar}=${now}`;
      }
      window.location.search = search;
    }
  },
  getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return 0;
  },
}));
