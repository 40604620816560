define([
  'underscore',
  'modules/shop.cash-register-retail/models/settings/shopPos',
  'modules/common/components/component',
  'modules/common/components/locale',
], (
  _, ShopPosModel, Component, Locale,
) => {
  const WEIGHTED_BARCODE_REGEX = '210bbbbpppppc';
  const EMBALLAGE_BARCODE_REGEX = '98012345ppppc';
  const MATCH_PATTERN = /^(?<prefix>\d+)(?<c1>c*)(?<barcode>b*)(?<c2>c*)(?<price>p+)(?<c3>c+)$/;

  const Comp = Component.extend({
    TYPE_WEIGHTED: 'weighted',
    TYPE_EMBALLAGE: 'emballage',

    initialize(options) {
      Component.prototype.initialize.call(this, options);
      this.patterns = null;
    },
    match(query) {
      const patterns = this.compilePatterns();
      let found = false;
      _.each(patterns, (regx, type) => {
        if (!found) {
          const match = query.match(regx);
          if (match) {
            if (this.isEan(query)) {
              const { price = null, barcode = null } = match.groups;
              let priceFormatted = null;
              if (price) {
                priceFormatted = (parseFloat(price) / 100).toFixed(2);
              }
              found = {
                type,
                query,
                price: priceFormatted,
                barcode,
              };
            } else {
              throw new Error(
                Locale.translate('invalid_barcode_{query}_for_{type}', {
                  query, type,
                }),
              );
            }
          }
        }
      });
      return found;
    },
    getPatterns() {
      if (!this.patterns) {
        this.patterns = this.compilePatterns();
      }
      return this.patterns;
    },
    compilePatterns() {
      const defined = ShopPosModel.getBarcodePatterns();
      return {
        weighted: this.makePattern(defined.weighted || WEIGHTED_BARCODE_REGEX),
        emballage: this.makePattern(defined.emballage || EMBALLAGE_BARCODE_REGEX),
      };
    },
    makePattern(from) {
      const match = from.match(MATCH_PATTERN);
      if (match === null) {
        throw new Error(`Failed to compile barcode pattern: ${from}`);
      }
      const { groups } = match;
      let pattern = '^';
      pattern += groups.prefix;
      if (groups.c1) {
        pattern += `\\d{${groups.c1.length}}`;
      }
      if (groups.barcode) {
        pattern += `(?<barcode>\\d{${groups.barcode.length}})`;
      }
      if (groups.c2) {
        pattern += `\\d{${groups.c2.length}}`;
      }
      if (groups.price) {
        pattern += `(?<price>\\d{${groups.price.length}})`;
      }
      if (groups.c3) {
        pattern += `\\d{${groups.c3.length}}`;
      }
      pattern += '$';
      return new RegExp(pattern);
    },
    /**
         * @docs https://stackoverflow.com/questions/13605340/how-to-validate-a-ean-gtin-barcode-in-javascript#answer-19629719
         * @param code
         * @returns {boolean}
         */
    isEan(code) {
      const checkSum = code.split('').reduce((p, v, i) => (i % 2 == 0 ? p + 1 * v : p + 3 * v), 0);
      return checkSum % 10 === 0;
    },
  });
  return new Comp();
});
