define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/shop.common/collections/upx/NaturalSearchShopFlatProduct',
  'modules/shop.cash-register-retail/collections/currentOrderItem',

  'upx.modules/ShopModule/models/ShopFlatProduct',
  'modules/shop.cash-register-retail/collections/TaxRate.js',

  'modules/shop.cash-register-retail/views/popups/hospitalityMembercardPopup',
  'modules/shop.cash-register-retail/views/popups/giftcardPopup',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/views/popups/membercardPopup',
  'modules/shop.cash-register-retail/views/popups/usedGoodPopup.js',

  'upx.modules/PaymentModule/models/GiftCard',
  'upx.modules/ShopModule/models/MemberCard',

  'upx.modules/RelationsModule/collections/FullInfoRelation',
  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/shop.cash-register-retail/components/feature.js',

  'toastr',
  'modules/common/components/locale',
], (
  $, _, Backbone, Component,
  DefaultShopConfigurationModel, NaturalSearchShopFlatProductCollection, OrderItemCollection,
  ShopFlatProductModel, TaxRateCollection,
  MembercardWithGiftCardPopup, GiftcardPopupView, MessagePopupView, MembercardPopupView, UsedGoodPopupView,
  GiftcardModel, MemberCardModel,
  FullInfoRelationCollection, SelectedCustomerModel, Feature,
  Toastr, Locale,

) => {
  const Comp = Component.extend({

    initialize() {
      this.productDef = null;
      this.giftBalanceProduct = null;
      this.memberProduct = null;
      this.usedGoodsProduct = null;
    },

    loadProducts() {
      if (this.productDef === null || this.productDef.state() === 'rejected') {
        this.productDef = this.forceLoadProducts();
      }
      return this.productDef;
    },

    loadConfig() {
      const configDef = new $.Deferred();
      DefaultShopConfigurationModel.load().then(() => {
        if (
          !DefaultShopConfigurationModel.getMemberCardProductId()
            || !DefaultShopConfigurationModel.getGiftCardBalanceProductId()
        ) {
          DefaultShopConfigurationModel.ensureProducts().then(
            (data) => {
              DefaultShopConfigurationModel.unload();
              DefaultShopConfigurationModel.set(data);
              configDef.resolve();
            },
            configDef.reject,
          );
        } else {
          configDef.resolve();
        }
      });
      return configDef;
    },

    forceLoadProducts() {
      const productDef = new $.Deferred();
      const configDef = this.loadConfig();

      configDef.then(
        () => {
          const memberId = DefaultShopConfigurationModel.getMemberCardProductId();
          const giftCardId = DefaultShopConfigurationModel.getGiftCardBalanceProductId();
          const usedGoodsId = DefaultShopConfigurationModel.getUsedGoodsProductId();
          const collection = new NaturalSearchShopFlatProductCollection();
          const product_ids = [memberId, giftCardId];
          if (usedGoodsId) {
            product_ids.push(usedGoodsId);
          }
          const params = {
            params: {
              query: 0,
              lang: '',
              start: 0,
              limit: 0,
              filters: [{
                name: 'flat_product/product/id__in_list',
                multi_val: product_ids,
              }],
            },
          };

          collection.fetch(params).then(() => {
            const memberProduct = collection.findWhere({ product_id: memberId });
            const giftBalanceProduct = collection.findWhere({ product_id: giftCardId });
            const usedGoodsProduct = collection.findWhere({ product_id: usedGoodsId });
            productDef.resolve({
              memberProduct,
              giftBalanceProduct,
              usedGoodsProduct,
            });
          }, productDef.reject);
        },
        productDef.reject,
      );

      productDef.then(
        ({
          memberProduct,
          giftBalanceProduct,
          usedGoodsProduct,
        }) => {
          this.memberProduct = memberProduct;
          this.giftBalanceProduct = giftBalanceProduct;
          this.usedGoodsProduct = usedGoodsProduct;
        },
        (err) => {
          Toastr.error(Locale.translate('failed_to_load_the_products'));
        },
      );
      return productDef;
    },

    addGiftCardBalanceProduct(type) {
      return OrderItemCollection.addProductByShopFlatProductModel(
        this.giftBalanceProduct,
        1,
        true,
        type || OrderItemCollection.TYPE_TOP_UP,
      );
    },
    addMemberCardProduct() {
      return OrderItemCollection.addProductByShopFlatProductModel(
        this.memberProduct,
        1,
        true,
        OrderItemCollection.TYPE_MEMBERCARD,
      );
    },

    startLoader() {
      const def = new $.Deferred();
      OrderItemCollection.addLoaderByDef(def);

      def.fail((error) => {
        const view = new MessagePopupView();
        view.open(error.error);
      });
      return def;
    },

    rejectIfExistsInCheckout(code, def) {
      if (OrderItemCollection.hasCardWithCode(code)) {
        def.reject({
          error: Locale.translate('a_card_with_code_{code}_has_already_been_added', { code }),
          class: 'AlreadyExistsInCheckout',
        });
        return true;
      }
      return false;
    },

    usedGoodsPopup(def, tax_rate_id) {
      def = def || this.startLoader();
      $.when(
        this.loadProducts(),
        Feature.load(),
        TaxRateCollection.load(),
      ).then(
        () => {
          if (this.usedGoodsProduct) {
            this.usedGoodsPopupLoad(def, tax_rate_id);
          } else {
            def.reject({ error: Locale.translate('used_good_feature_is_not_enabled') });
          }
        },
        def.reject,
      );
      return def;
    },

    usedGoodsPopupLoad(def, tax_rate_id) {
      const viewDef = new $.Deferred();
      const taxRateModel = TaxRateCollection.get(tax_rate_id);
      const view = new UsedGoodPopupView({
        shopProductModel: this.usedGoodsProduct,
        taxRateLabel: TaxRateCollection.getTaxRateTitleForModel(taxRateModel),
      });
      view.open(viewDef);

      $.when(viewDef).then(({
        name, description, ppu_wt, serial_nos,
      }) => {
        const shopProduct = new ShopFlatProductModel(this.usedGoodsProduct.toJSON());
        shopProduct.set('flat_product.title', name);
        shopProduct.set('product_price.tax', taxRateModel.get('value'));
        shopProduct.set('product_price.tax_rate_id', taxRateModel.get('id'));

        const item = OrderItemCollection.addSimpleProductByShopFlatProductModel(
          shopProduct,
          -1,
          true,
          OrderItemCollection.TYPE_USED_GOODS,
        );
        item.setOrderDescription(description);
        item.setDescription(description);
        item.setSerialNos(serial_nos, true);
        item.setPpuWt(ppu_wt);
        item.save();

        item.trigger('change:description'); // trigger the change to render the checkout row
        def.resolve();
      }, def.reject);
    },

    topUpPopup(def) {
      def = def || this.startLoader();
      this.loadProducts().then(
        () => this.topUpPopupWithoutLoad(def),
        def.reject,
      );
      return def;
    },

    topUpPopupWithoutLoad(def) {
      const viewDef = new $.Deferred();
      const view = new GiftcardPopupView({
        topUp: true,
      });
      view.open(viewDef, Locale.translate('top_up'));
      $.when(viewDef).then((data) => {
        const { code } = data;
        if (this.rejectIfExistsInCheckout(code, def)) {
          return; // was rejected
        }

        const giftCardModel = new GiftcardModel();
        giftCardModel.find({ code }).then(() => {
          this.addGiftCardBalanceProduct(OrderItemCollection.TYPE_TOP_UP).then(
            (item) => {
              item.setPpuWt(data.balance);
              item.set({
                name: Locale.translate('top_up_code_{0}', code),
                notes: item.get('summary'),
                code,
              });
              item.save();
              def.resolve();
            },
            def.reject,
          );
        }, () => def.reject({ error: Locale.translate('card_with_code_{0}_not_found_exclamation', code) }));
      }, def.reject);
    },

    giftCardPopup(def) {
      def = def || this.startLoader();
      this.loadProducts().then(
        () => this.giftCardWithoutLoad(def),
        def.reject,
      );
      return def;
    },

    giftCardWithoutLoad(def) {
      const viewDef = new $.Deferred();
      const view = new GiftcardPopupView();
      view.open(viewDef, Locale.translate('payment_card'));

      $.when(viewDef).then((data) => {
        const { code } = data;
        if (this.rejectIfExistsInCheckout(code, def)) {
          return; // was rejected
        }
        const { balance } = data;
        const memberName = data.relation_data_name;

        const giftCardModel = new GiftcardModel();
        giftCardModel.find({ code }).then(
          () => def.reject({ error: Locale.translate('payment_card_already_sold') }),
          () => {
            this.addGiftCardBalanceProduct(OrderItemCollection.TYPE_GIFTCARD).then(
              (item) => {
                const productName = item.get('name');
                item.setPpuWt(balance);

                // Get name
                let name = Locale.translate('{0}_with_code_{1}', [productName, code]);
                if (memberName) { // member is choosen
                  name = Locale.translate('{0}_for_{1}_with_code_{2}', [
                    productName,
                    memberName,
                    code,
                  ]);
                }

                // update model
                item.set({
                  name,
                  notes: item.get('summary'),
                  code,
                });
                item.save();
                def.resolve();
              },
              def.reject,
            );
          },
        );

        def.fail((error) => {
          const view = new MessagePopupView();
          view.open(error.error);
        });
      }, def.reject);
    },

    memberCardWithBalancePopup(def) {
      def = def || this.startLoader();
      this.loadProducts().then(
        () => this.memberCardWithBalanceWithoutLoad(def),
        def.reject,
      );
      return def;
    },

    memberCardWithBalanceWithoutLoad(def) {
      const viewDef = new $.Deferred();
      const view = new MembercardWithGiftCardPopup({
        selectCustomer: true,
        selectAmount: true,
      });
      view.open(viewDef);
      $.when(viewDef).then((data) => {
        const { code } = data;
        if (this.rejectIfExistsInCheckout(code, def)) {
          return; // was rejected
        }

        const { balance } = data;
        const memberRelationDataId = data.relation_data_id;
        const memberName = data.relation_data_name;

        const fullInfoRelationCollection = new FullInfoRelationCollection();
        const params = {
          params: {
            start: 0,
            limit: 1,
            filters: [{
              name: 'id__=',
              val: memberRelationDataId,
            }],
          },
        };
        fullInfoRelationCollection.fetch(params)
          .then(() => {
            const model = fullInfoRelationCollection.first();
            SelectedCustomerModel.clear();
            SelectedCustomerModel.set(model.toJSON());
            SelectedCustomerModel.save();

            /**
                         * Giftcard check
                         */
            const giftDef = new $.Deferred();
            const giftcardModel = new GiftcardModel();
            giftcardModel.find({ code })
              .then(() => {
                giftDef.reject({
                  error: Locale.translate('a_gift_card_with_the_code_{0}_already_exist', code),
                  class: 'AlreadyExists',
                });
              }, (resp) => {
                if (resp.class === 'Lookup') {
                  giftDef.resolve();
                } else {
                  giftDef.reject(resp);
                }
              }, def.reject);

            /**
                         * Customer card check
                         */
            const memberDef = new $.Deferred();
            const memberCardModel = new MemberCardModel();
            giftDef
              .then(() => {
                memberCardModel.find({ code })
                  .then(() => {
                    memberDef.reject({
                      error: Locale.translate('a_customer_card_with_the_code_{0}_already_exist', code),
                      class: 'AlreadyExists',
                    });
                  }, (resp) => {
                    if (resp.class === 'Lookup') {
                      memberDef.resolve();
                    } else {
                      memberDef.reject(resp);
                    }
                  });
              }, def.reject);

            /**
                         * Product fetch
                         */
            memberDef.then(() => {
              this.addGiftCardBalanceProduct(OrderItemCollection.TYPE_MEMBERCARD_GIFTCARD).then(
                (item) => {
                  item.setPpuWt(balance);
                  item.set({
                    name: Locale.translate('{0}_for_{1}_with_code_{2}', [
                      item.get('name'),
                      memberName,
                      code,
                    ]),
                    notes: item.get('summary'),
                    code,
                    // we need it here, because there can be multiple for different relations
                    relation_data_id: memberRelationDataId,
                  });
                  item.save();
                  def.resolve();
                },
                def.reject,
              );
            }, () => {
              def.reject({ error: Locale.translate('customer_not_found_after_creation') });
            });
          }, def.reject);
      }, def.reject);
    },

    memberCardPopup(def) {
      def = def || this.startLoader();
      this.loadProducts().then(
        () => this.memberCardWithoutLoad(def),
        def.reject,
      );
      return def;
    },

    memberCardWithoutLoad(def) {
      if (!SelectedCustomerModel.has('id')) {
        def.reject({
          error: Locale.translate('select_a_customer_first_dot'),
        });
        return;
      }
      const viewDef = new $.Deferred();
      const view = new MembercardPopupView();
      view.open(viewDef);
      viewDef.then((object) => {
        const { code } = object;
        if (this.rejectIfExistsInCheckout(code, def)) {
          return; // was rejected
        }
        const cardModel = new MemberCardModel({
          code,
        });
        cardModel.find({ code })
          .then(() => {
            def.reject({
              error: Locale.translate('customer_card_already_sold'),
            });
          }, () => {
            this.addMemberCardProduct().then(
              (model) => {
                model.set({
                  name: `${model.get('name')}, code: ${code}`,
                  notes: model.get('summary'),
                  type: OrderItemCollection.TYPE_MEMBERCARD,
                  code,
                });
                model.save();
                def.resolve();
              },
              def.reject,
            );
          }, def.reject);
      }, def.reject);
    },
  });

  return new Comp();
});
