define([
  'jquery',
  'application',

  'modules/common/components/component',
  'dexie',
  'modules/common/crontabs/cron',
  'modules/shop.cash-register-retail/components/autoReload',
], (
  $, App,
  Component, Dexie, Cron, AutoReload,
) => {
  const c = Component.extend({
    initialize() {
      this.db = new Dexie('failedCashRegisterApiLog');
      this.doFlush = false;
      this.flushing = false;
      this.db.version(2).stores({
        failed: 'time',
      });

      const cronClass = Cron.extend({
        cron: '* * * * *', // each minute
        run: () => {
          this.tryFlush();
        },
      });
      this.cron = new cronClass();
      if (location.hash !== '#customer-screen') {
        this.cron.start();
      }
    },

    async addFailed(data, error) {
      // force to object, and strip all unserializable data
      // if not done it will cause the Failed to execute 'put' on 'IDBObjectStore': #<Object> could not be cloned. error
      data = JSON.parse(JSON.stringify(data));
      await this.db.failed.put({
        time: new Date().getTime(),
        data,
        error,
      });
    },

    startFlush(logFn) {
      this.doFlush = logFn;
      this.tryFlush();
    },

    async tryFlush(force) {
      if (this.doFlush && (!this.flushing || force)) {
        this.flushing = true;
        try {
          const row = await this.db.failed.toCollection().first();
          if (row) {
            const { data } = row;
            await this.db.failed.delete(row.time); // delete the row, it will be readed if fails
            await this.doFlush(
              data.file,
              data.level,
              data.content,
              data.options,
              data.date,
            );

            await this.tryFlush(true);
          } else {
            // empty collection
            this.doFlush = false;
          }
        } catch (e) {
          const warn = this.getOriginalWarn();
          warn('Failed to flush cash register api log', e);

          if (e instanceof Dexie.DexieError) {
            // dixie error lets reload the browser
            warn('Dixie error on connection, reloading the browser', e);
            AutoReload.reloadOnError(e);
          }
        } finally {
          this.flushing = false;
        }
      }
    },

    getOriginalWarn() {
      return console.warnOriginal || console.warn;
    },

  });

  return new c();
});
