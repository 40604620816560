define([
  'modules/common/components/locale',
], (Locale) => ({

  getTranslatedRoles() {
    return {
      MAIN: Locale.translate("main_contact"),
      TECHNICAL: Locale.translate("technical_contact"),
      FINANCIAL: Locale.translate("financial_contact"),
      OPERATIONAL: Locale.translate("operational_contact"),
      LOGISTICAL: Locale.translate("logistical_contact"),
      LEGAL: Locale.translate("legal_contact"),
    };
  },

  getTranslatedRole(role_key) {
    const roles = this.getTranslatedRoles();
    if (role_key in roles) {
      return roles[role_key];
    }
    return null;
  },
}));
