define([
  'jquery',
  'underscore',
  'backbone',
  'application',

  'modules/common/components/component',
  'modules/common/components/managers/configuration',
  'modules/common/components/locale',
  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.common/components/mode',

  'modules/profile/models/profile',
  'upx.modules/ManagementModule/models/Session',

  'modules/upx/collections/users',
], (
  $, _, Backbone, App,
  Component, ConfigurationManager, Locale, DeviceConfig, CashRegisterApi, Mode,
  ProfileModel, SessionModel,
  UserCollection,
) => {
  const Comp = Component.extend({

    initialize() {

    },

    getBackofficeUrl() {
      return App.com(ConfigurationManager)
        .get('upx')
        .get('server')
        .replace('api-', '');
    },

    openBackoffice() {
      const def = new $.Deferred();

      const activeUser = UserCollection.getActiveUser();
      const sessionModel = new SessionModel();
      const sessionParameters = {
        subaccount_id: ProfileModel.get('subuser.subaccount_id'),
        subuser_id: ProfileModel.get('subuser.id'),
      };

      sessionModel.startSubuser(sessionParameters)
        .then((hash) => {
          const deviceUuid = DeviceConfig.getDeviceUUID();
          const backofficeUrl = this.getBackofficeUrl();
          const loginHash = btoa(JSON.stringify({
            rights: activeUser.get('rights'),
            mode: 'hash',
            account: activeUser.get('tree_root_shortname'),
            subaccount: activeUser.get('subaccount'),
            user: ProfileModel.get('subuser.name'),
            hash,
            client_name: 'anonymous',
            locale: Locale.getLocale(),
            longExpiryHash: activeUser.get('longExpiryHash'),
          }));

          const url = `${backofficeUrl}?mode=kassa&uuid=${deviceUuid}/#auth/loginByHash/${loginHash}`;

          if (Mode.isInElectronMode()) {
            window.electron.openExternalBrowser(url);
            def.resolve();
          } else {
            const urlHash = btoa(url);
            CashRegisterApi.call(`/open-in-chromium?url=${urlHash}`, 'GET')
              .then(def.resolve, def.reject);
          }
        }, def.reject);

      return def;
    },

  });

  return new Comp();
});
