define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',

  'modules/shop.cash-register-retail/views/popups/productAddonPopup',
  'modules/shop.cash-register-retail/components/productAddon',

  'modules/common/collections/DeepModelCollection',
  'modules/shop.common/components/crypto.js',
], (
  $, _, Backbone, Component,
  ProductAddonPopup, ProductAddon,
  DeepModelCollection, Crypto,

) => {
  const Comp = Component.extend({

    getRequiredSubItems(collection) {
      const subItems = [];
      collection.each((model) => {
        const items = model.get('shop_product_addon_items');

        if (_.isArray(items)) {
          items.forEach((item) => {
            subItems.push({
              ppu_wt: item.product_price.ppu_wt,
              currency_iso3: item.product_price.currency_iso3,
              quantity: '1',
              sku: item.sku,
              name: item.title,
              shop_product_id: item.shop_product_id,
            });
          });
        }
      });
      return subItems;
    },

    showAddonPopup(orderItemModel, configurableModel) {
      const def = new $.Deferred();
      orderItemModel.trigger('show:loader', def);

      const confDeferred = configurableModel
        ? ProductAddon.fetchShopProductAddonForId(configurableModel.get('id'))
        : (new $.Deferred()).resolve();

      $.when(
        ProductAddon.fetchShopProductAddonForId(orderItemModel.get('shop_product_id')),
        confDeferred,
      ).then(
        (singleCollection, confCollection) => {
          const addonCollection = new DeepModelCollection(singleCollection.toJSON());
          addonCollection.comparator = 'order';

          if (confCollection) {
            confCollection.each((model) => {
              if (!addonCollection.get(model.get('id'))) {
                addonCollection.add(model);
              }
            });
          }
          // If the type is required addon it shouldn't give a productAddonPopup
          const requiredCollection = new DeepModelCollection();
          addonCollection.each((model) => {
            const type = model.get('product_addon_group.type');
            if (type === 'required-addon') {
              addonCollection.remove(model);
              requiredCollection.add(model);
            }
          });

          const requiredSubItems = this.getRequiredSubItems(requiredCollection);
          if (requiredSubItems.length > 0) {
            orderItemModel.set('sub_items', this.prepareSubitems(requiredSubItems));
            orderItemModel.save();
          }
          if (addonCollection.length > 0) {
            const view = new ProductAddonPopup({
              collection: addonCollection,
            });
            view.open();
            view.on('sub_items:selected', (sub_items) => {
              // Concat the selected items and the required items.
              const new_sub_items = [].concat(sub_items, requiredSubItems);

              orderItemModel.set('sub_items', this.prepareSubitems(new_sub_items));
              orderItemModel.save();
              def.resolve();
            });
            view.on('popup:closed', () => {
              def.resolve();
            });
          } else {
            def.resolve();
          }
        }, def.reject,
      );

      return def;
    },

    prepareSubitems(new_sub_items) {
      if (new_sub_items && new_sub_items.length) {
        new_sub_items.forEach((item) => [
          item.extra = {
            frontend_id: Crypto.uuid(),
          },
        ]);
      }
      return new_sub_items;
    },
  });

  return new Comp();
});
