define([
    'jquery',
    'underscore',
    'backbone',
    'modules/common/components/component',
    'modules/shop.cash-register-retail/components/cashRegisterApi',
], function (
    $, _, Backbone, Component, CashRegisterApi
) {
    var Comp = Component.extend({

        initialize: function () {
            this.setOriginal();
            if( CashRegisterApi.isApiAvailable() ){
                this.relayLog();
                this.relayWarn();
                this.relayError();
                this.relayWindowError();
            }
        },
    
        setOriginal: function () {
            console.logOriginal = console.log;
            console.warnOriginal = console.warn;
            console.errorOriginal = console.error;
        },

        relayLog: function () {
            let self = this;
            console.log = function () {
                console.logOriginal.apply( console, arguments );
                self.report('LOG', arguments);
            };
        },

        relayWarn: function () {
            let self = this;
            console.warn = function () {
                console.warnOriginal.apply( console, arguments );
                self.report('WARNING', arguments);
            };
        },

        relayError: function () {
            let self = this;
            console.error = function () {
                console.errorOriginal.apply( console, arguments );
                self.report('ERROR', arguments);
            };
        },

        relayWindowError: function () {
            window.onerror = (error, url, line) => {
                this.report('FATAL', [{acc:'error', data:'ERR:'+error+' URL:'+url+' L:'+line}]);
            };
        },

        report: function (level, arguments) {
            try {
                // setup content for debugging
                let content = {};
                if ( arguments.length <= 1 ) {
                    content.msg = _.first(arguments);
                } else {
                    content.msg = arguments;
                }
                
                // error for stack tracing
                let err = new Error();
                content.trace = err.stack;
                
                CashRegisterApi.log("browser-console", level, content)
                    .fail(errorResponse => {
                        // Make sure if call to the CashRegisterApi fails
                        console.warnOriginal( 'Failed to log the "browser-console"', level, content, errorResponse, err );
                    });
            } catch (e) {
                // prevent a loop or errors.
            }
        },

    });

    return new Comp();
});